import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';

/**
 * The primary menu component rendered in the header
 *
 * Loads menu data/items from the constants file at
 * ../../constats/Menu
 */
const Menu = ({ menuData }) => {
  const [currentlyOpen, setCurrentlyOpen] = React.useState();

  // Closes the menu when the user clicks outside of it
  const handleOutsideClick = React.useCallback((e) => {
    const isOutside = !e.target.closest('[data-menu-item]');

    if (isOutside) {
      setCurrentlyOpen(null);
      window.removeEventListener('mousemove', handleOutsideClick);
    }
  }, [setCurrentlyOpen]);

  const handleSetCurrentlyOpen = React.useCallback((id) => {
    setCurrentlyOpen(id);
    window.addEventListener('mousemove', handleOutsideClick);
  }, [setCurrentlyOpen, handleOutsideClick]);

  return (
    <nav className="hidden lg:flex">
      {menuData.menuItem.map((item) => (
        <MenuItem
          key={item.id}
          currentlyOpen={currentlyOpen === item.id}
          setCurrentlyOpen={handleSetCurrentlyOpen}
          {...item}
        />
      ))}
    </nav>
  );
};

Menu.propTypes = {
  menuData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    menuItem: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sublinks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string.isRequired
        }).isRequired,
        url: PropTypes.string.isRequired
      }))
    }))
  }).isRequired

};

Menu.defaultProps = {
  setCurrentlyOpen: () => {}
};

export default Menu;
