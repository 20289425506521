import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import CONFIG from '../../Config';
import Logo from '../Logo';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import LinkButton from '../Shared/LinkButton';

const MENU_QUERY = graphql`
  query MenuQuery {
    contentfulHeaderMenu {
      id
      primaryButton {
        label
        url
      }
      menuItem {
        id
        label
        sublinks {
          id
          label
          icon
          description {
            description
          }
          url
        }
      }
    }
  }
`;


const Header = ({ siteTitle }) => {
  const { contentfulHeaderMenu: menuData } = useStaticQuery(MENU_QUERY);
  const { primaryButton } = menuData;
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return (
    <React.Fragment>
      <div className="sticky z-10 top-0 left-0 right-0">
        <header className="z-10 width-full bg-white border-b border-gray-100 shadow">
          <div className="relative">
            <div className={`
              flex
              justify-between
              items-center
              px-8
              py-6
              sm:px-10
              md:justify-start
              md:space-x-10
              w-full
              max-w-screen-xl
              mx-auto
              `}
            >
              <div className="lg:w-0 md:flex-1">
                <Link
                  aria-label="Link to home"
                  to="/"
                  className="flex"
                >
                  <div className="logo-width">
                    <Logo />
                  </div>
                </Link>
              </div>
              <Menu menuData={menuData} />
              <div className="-mr-2 -my-2 lg:hidden">
                <button
                  type="button"
                  className="inline-flex flex-col items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                  Menu
                </button>
              </div>
              {CONFIG.header.button && (
                <div className="hidden lg:flex items-center justify-end space-x-8 lg:flex-1 lg:w-0">
                  <span className="inline-flex shadow-sm">
                    <LinkButton.Red target="_blank" to={primaryButton.url}>
                      {primaryButton.label}
                    </LinkButton.Red>
                  </span>
                </div>
              )}
            </div>
          </div>
        </header>
      </div>
      {mobileMenuOpen && (
        <MobileMenu
          menuData={menuData}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      )}
    </React.Fragment>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
