import React from 'react';
import PropTypes from 'prop-types';
import FlyoutMenu from './FlyoutMenu';

/**
 * Main component for menu items (links) in the header primary menu
 * Renders submenus into the main header container using React portals
 */
const MenuItem = (props) => {
  const { id, label, currentlyOpen, setCurrentlyOpen, sublinks } = props;

  const handleButtonHover = React.useCallback(() => {
    setCurrentlyOpen(id);
  }, [id, setCurrentlyOpen]);

  return (
    <React.Fragment>
      <div data-menu-item className="relative">
        <button
          type="button"
          onMouseOver={handleButtonHover}
          onFocus={handleButtonHover}
          className={`
            text-gray-900
            group
            inline-flex
            items-center
            space-x-2
            px-4
            text-base
            xl:text-md
            leading-6
            font-medium
            hover:text-periwinkle-base
            focus:outline-none
            focus:text-periwinkle-base
            transition
            ease-in-out
            duration-150
          `}
          data-item-id={id}
        >
          <span>{label}</span>
        </button>
        <div className={currentlyOpen ? '' : 'hidden'}>
          <FlyoutMenu sublinks={sublinks} />
        </div>
      </div>
    </React.Fragment>
  );
};

MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  currentlyOpen: PropTypes.bool.isRequired,
  setCurrentlyOpen: PropTypes.func.isRequired,
  sublinks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string.isRequired
      }).isRequired,
      url: PropTypes.string.isRequired
  }))
};

MenuItem.defaultProps = {
  currentlyOpen: false,
  setCurrentlyOpen: () => {}
};

export default MenuItem;
