import React from "react"

import Menu from "./Menu"
import Logo from "../Logo"
import LinkButton from "../Shared/LinkButton"
import MobileMenuItem from "./MobileMenuItem"
import { useLockScroll } from "../../utils/Scroll"

const MobileMenu = ({ menuData, setMobileMenuOpen }) => {
  const { primaryButton, menuItem } = menuData
  const [headerHeight, setHeaderHeight] = React.useState(0)
  const headerRef = React.useRef()
  useLockScroll()

  React.useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  }, [setHeaderHeight])

  return (
    <React.Fragment>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 z-20"
        style={{ background: "rgba(0, 0, 0, 0.5" }}
      />
      <div className="fixed top-3 left-3 right-3 bottom-3 z-20">
        <div className="w-full relative bg-white rounded-lg shadow-lg h-full">
          <div
            ref={headerRef}
            className="absolute top-0 left-0 right-0 bg-white border-b pt-6 pb-4 mx-4 border-gray-100 z-10"
          >
            <div className="" style={{ width: 140 }}>
              <Logo />
            </div>
            <LinkButton.Red
              className="mt-6 w-full inline-flex"
              to={primaryButton.url}
              target="_blank"
              onClick={() => setMobileMenuOpen(false)}
            >
              {primaryButton.label}
            </LinkButton.Red>
          </div>
          <div className="absolute top-2 right-2" style={{ zIndex: 200 }}>
            <button
              onClick={() => setMobileMenuOpen(false)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div
            className="w-full h-full px-4 overflow-scroll"
            style={{ paddingTop: `calc(${headerHeight}px - .75rem)` }}
          >
            {menuItem.map(item => (
              <MobileMenuItem
                key={item.id}
                {...item}
                setMobileMenuOpen={setMobileMenuOpen}
              />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

MobileMenu.propTypes = Menu.propTypes
export default MobileMenu
